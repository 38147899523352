import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { UserMinimalDto } from "../../shared/dtos/user/user.minimal.dto";
import { UserRequestParams } from "../../shared/models/request.params";
import { UserService } from "../../shared/services/user.service";
import { PageResponse } from '../../shared/dtos/page-response.dto';


@Injectable({ providedIn: 'root' })
export class UserResolver {

    constructor(private userService: UserService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<PageResponse<UserMinimalDto>> {
		return this.userService.getByFilters(route.queryParams as UserRequestParams)
    }

}
