import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { BookingService } from "../../shared/services/booking.service";


@Injectable({ providedIn: 'root' })
export class BookingResolver {

    constructor(private bookingService: BookingService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
		return this.bookingService.getBooking(route.params.id)
    }
}
