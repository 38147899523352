import { Directive, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { debounceTime, filter, map } from "rxjs";
import { UserMinimalDto } from "../dtos/user/user.minimal.dto";
import { ComparatorService } from "../services/comparator.service";
import { BookingRequestParams, RequestParams } from "./request.params";

@Directive()
export abstract class ClientFilterComponent {

	@ViewChild(MatAutocompleteTrigger) auto: MatAutocompleteTrigger;
	customers: UserMinimalDto[] = [];
	filteredCustomers: UserMinimalDto[] = [];
	form: FormGroup;

	constructor(protected comparatorService: ComparatorService) {}

	abstract initForm(params?: RequestParams);
	abstract reloadContent();

	protected watchSearchCustomers(): void {
        this.form.get('customerSearch').valueChanges.pipe(
			debounceTime(400),
			filter(value => !(value instanceof Object)),
			map((value: string) => {
				return value.length === 0
					? this.customers
					: this.customers.filter(c => {
						const cS = this.comparatorService.simplify(c.companyName + c.fullName)
						const val = this.comparatorService.simplify(value);
						return cS.includes(val)
					})
			})
		).subscribe(result => this.filteredCustomers = result);
    }

	protected initAutoComplete(params: BookingRequestParams): void {
		if (!!params.clientId && params.clientId > 0) {
			setTimeout(() => {
				const option = this.auto.autocomplete.options.find(o => o.value.id === parseInt(params.clientId as unknown as string))
				option.select();
				this.auto.writeValue(option.value)
			}, 0)
		}
	}

	protected clientDisplayName(c: UserMinimalDto): string {
		return !!c ? `${c.companyName} - ${c.fullName} - ${c.email}` : "";
	}

	protected customerChanged(event: MatAutocompleteSelectedEvent) {
		this.form.get("clientId").patchValue(event.option.value.id)
		this.reloadContent();
	}

}
