
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { UserMinimalDto } from "../../shared/dtos/user/user.minimal.dto";
import { EmptyPageResponse, PageResponse } from "../../shared/dtos/page-response.dto";
import { ClientRequestParams } from "../../shared/models/request.params";
import { UserService } from "../../shared/services/user.service";
import { ROLES } from "../../shared/enums/roles.enum";



@Injectable({ providedIn: 'root' })
export class CustomersResolver {

    constructor(private userService: UserService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<PageResponse<UserMinimalDto>> {

		if (this.userService.currentUser().role === ROLES.CLIENT) {
			return of(EmptyPageResponse);
		}

		return this.userService.getByFilters({
			...route.queryParams as ClientRequestParams,
			limit: 999999,
			role: ROLES.CLIENT,
			offset: 0
		})
    }

}
