import { UserService } from 'src/app/modules/shared/services/user.service';
import { Observable, combineLatest, debounceTime, delay, filter, map, of, skip, switchMap, take, tap } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AppService } from '../services/app.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard {

	isConnected: Observable<boolean>

	constructor(
		private userService: UserService,
		private authService: AuthService,
		private appService: AppService,
		private router: Router
	) {
		this.isConnected = combineLatest([
			toObservable(this.userService.currentUser),
			toObservable(this.authService.attemptedConnection),
			toObservable(this.appService.isLoading)
		]).pipe(
			switchMap(([user, attemptedConnection, isLoading]) => of([user, attemptedConnection, isLoading]).pipe(delay(60))),
			filter(([user, attemptedConnection, isLoading]) => !!attemptedConnection && !isLoading),
			map(([user, attemptedConnection, isLoading]) => !!user)
		);
	 }

	canActivate(): Observable<boolean> {
		return this.isConnected.pipe(
			tap(connected => {
				if (!connected) {
					this.router.navigate([]);
				}
				console.log("connected", connected)
			})
		)
	}
}
