<section *ngIf="!!form">
	<div class="sub-header">
		<h1>Edition devis</h1>
	</div>

	<form [formGroup]="form" *ngIf="form">
		<div id="table">
			<div class="line" style="margin-bottom: 1.5rem;">
				<div class="line-main">
					<div class="columns is-vcentered" id="table-header">
						<div class="column is-3">Désignation de la prestation</div>
						<div class="column is-2">Date (si applicable)</div>
						<div class="column is-2">Durée/Quantité</div>
						<div class="column is-2">Prix unitaire</div>
						<div class="column is-2">Remise %</div>
						<div class="column is-1">Prix HT</div>
					</div>
				</div>
				<div class="plus"></div>
			</div>
			<ng-container formArrayName="lines" *ngIf="lines">
				<div class="line" *ngFor="let line of lines.controls; let i = index;">
					<div class="line-main">
						<div class="columns" [formGroupName]="i">
							<!-- ACTIVITY -->
							<div class="column is-3">
								<div class="activity-name">{{line.get('activityName').value}}</div>
							</div>
							<!-- DATE -->
							<div class="column is-2">
								<div *ngIf="line.get('activityType').value !== ACTIVITY_TYPES.FICHE">
									{{helper.fullDateFr(line.get('date').value)}}
									<BR />
									{{helper.hourFr(line.get('date').value)}}
								</div>
							</div>
							<!-- OFFRE -->
							<div class="column is-2">
								<mat-form-field subscriptSizing="dynamic"
									*ngIf="line.get('activityType').value !== ACTIVITY_TYPES.AUTRE"
									[tooltipStr]="proposal.lines[i].invoiced ? 'Cette prestation est déjà facturée ou le devis a été accepté.' : '' ">
									<mat-select formControlName="offerId">
										<mat-option *ngFor="let o of proposal.lines[i].event.activity.offers"
											[value]="o.id">
											<span *ngIf="line.get('activityType').value != ACTIVITY_TYPES.FICHE">
												{{o.sessionDuration / 60}}h
											</span>
											<span *ngIf="line.get('activityType').value === ACTIVITY_TYPES.FICHE">
												{{o.sessionDuration}} fiches
											</span>
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
							<!-- PRICE -->
							<div class="column is-2">
								<div *ngIf="line.get('activityType').value !== ACTIVITY_TYPES.AUTRE">
									{{getUnitPrice(i)}}€
								</div>
								<mat-form-field subscriptSizing="dynamic"
									*ngIf="line.get('activityType').value === ACTIVITY_TYPES.AUTRE">
									<input matInput type="number" formControlName="onDemandPrice"
										[tooltipStr]="proposal.lines[i].invoiced || lockAdditionnalFees ? 'Cette prestation est déjà facturée ou le devis a été accepté.' : '' ">
								</mat-form-field>
							</div>
							<!-- DISCOUNT % -->
							<div class="column is-2">
								<mat-form-field subscriptSizing="dynamic"
									[tooltipStr]="proposal.lines[i].invoiced || lockAdditionnalFees ? 'Cette prestation est déjà facturée ou le devis a été accepté.' : '' ">
									<input matInput type="number" formControlName="priceVariation" [max]="20" [min]="0">
								</mat-form-field>
							</div>
							<!-- FINAL PRICE -->
							<div class="column is-1">
								<div>{{getLineTotalPrice(i).toFixed(2)}}€</div>
							</div>
						</div>
					</div>
					<div class="plus">
						<button class="button is-small is-primary"
						[tooltipStr]="proposal.lines[i].invoiced ? 'Cette prestation est déjà facturée, supprimez la facture correspondante pour supprimer la prestation' : 'Doubler la prestation'"
							[disabled]="proposal.lines[i].invoiced"
							(click)="copyLine(i)">
							<mat-icon style="font-size: 14px;">plus_one</mat-icon>
						</button>
						<button class="button is-small is-inverted is-danger"
							[tooltipStr]="proposal.lines[i].invoiced ? 'Cette prestation est déjà facturée, supprimez la facture correspondante pour supprimer la prestation' : 'Supprimer la prestation'"
							(click)="deleteLine(i)"
							[disabled]="proposal.lines[i].invoiced"
							*ngIf="lines.length > 1"
							>
							<mat-icon>delete</mat-icon>
						</button>
					</div>
				</div>
			</ng-container>
		</div>


		<div class="columns" style="margin-top: 4rem; padding-top: 3rem; font-weight: bold; border-top: 1px solid #000">
			<div class="column is-9 checkbox-line" style="height: 74px">
				<mat-checkbox
					formControlName="applyApplicationFees"
					[tooltipStr]="lockAdditionnalFees ? 'Cette demande est déjà tout en partie facturée, supprimez toutes les factures pour éditer ce champ.' : '' "
					[disabled]="lockAdditionnalFees">
					<span>Appliquer frais de dossier</span>
				</mat-checkbox>
			</div>
			<ng-container *ngIf="form.get('applyApplicationFees').value">
				<div class="column is-2">
					<mat-form-field subscriptSizing="dynamic">
						<input matInput type="number"
							formControlName="applicationFees"
							[tooltipStr]="lockAdditionnalFees ? 'Cette prestation est tout en partie déjà facturée, supprimez les factures correspondantes pour modifier ce champ.' : 'Les frais de dossier apparaiteront toujours sur la première facture émise.'">
					</mat-form-field>
				</div>
				<div class="column">€</div>
			</ng-container>
		</div>

		<div class="columns">
			<div class="column is-10 checkbox-line"><span style="margin-left: 44px;">TOTAL HT</span></div>
			<div class="column is-1" style="text-align: right;">{{getTotalHT().toFixed(2)}}€</div>
		</div>

		<div class="columns" style="margin-bottom: 0; padding: 0; margin-top: 0rem;">
			<div class="column is-10 checkbox-line">
				<mat-checkbox
					formControlName="applyVAT"
					[tooltipStr]="lockAdditionnalFees ? 'Cette demande est déjà tout en partie facturée, supprimez toutes les factures pour éditer ce champ.' : '' ">
					<span>TVA 20%</span>
				</mat-checkbox>
			</div>
			<div class="column is-1" style="text-align: right;">{{this.form.get('applyVAT').value ? getVat().toFixed(2)+"€" : ""}}
			</div>
		</div>

		<div class="columns" style="font-weight: bold; margin-bottom: 0; margin-top: 1.5rem;">
			<div class="column is-9 checkbox"><span style="margin-left: 44px;">TOTAL TTC</span></div>
			<div class="column is-2" style="font-size: 26px; text-align: right;">{{getTotalTTC().toFixed(2)}}€</div>
		</div>
	</form>

	<div id="recapitulatif" style="margin-top: 4rem; padding-top: 3rem; border-top: 1px solid #000">
		<h1>Récapitulatif</h1>
		<table class="table is-striped is-fullwidth">
			<thead>
				<tr>
					<th>Activité</th>
					<th>Offre</th>
					<th>Quantité</th>
					<th>% Réduction moyen</th>
					<th>Montant TTC</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let line of recap">
					<td>{{line[0].event.activity.title}}</td>
					<td>{{line[0].event.offer.proposalDescription }}</td>
					<td>{{line.length}}</td>
					<td>{{getRecapLineDiscount(line).toFixed(2)}}%</td>
					<td>{{getRecapLineSum(line).toFixed(2)}}€</td>
				</tr>
				<tr *ngIf="form?.get('applyApplicationFees').value">
					<td>Frais de dossier</td>
					<td colspan="3">
						<!-- <i>(Facturés à l'occasion de la première facture émise)</i> -->
					</td>
					<td>{{getApplicationFeesTTC().toFixed(2)}}€</td>
				</tr>
			</tbody>
		</table>
	</div>


	<div id="buttons">
		<button class="button is-primary"
			[disabled]="!form.valid"
			(click)="pdf()"
			[ngClass]="{'is-fullwidth' : appService.isMobile() }"
			>
			<mat-icon>picture_as_pdf</mat-icon>
			Exporter en PDF
		</button>
	</div>
</section>
