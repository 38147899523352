import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { EventDto, ReservationSlotDto } from 'src/app/modules/shared/dtos/event/event.dto';
import { EventService } from 'src/app/modules/shared/services/event.service';

@Component({
  selector: 'app-event-calendar-modal',
  templateUrl: './event-calendar-modal.component.html',
  styleUrl: './event-calendar-modal.component.scss'
})

export class EventCalendarModalComponent implements OnInit {

	event: EventDto;

	constructor(
		private dialogRef: DialogRef,
		private eventService: EventService
	) {}

	async ngOnInit() {
		this.event = await firstValueFrom(this.eventService.getEvent(this.dialogRef.config.data.eventId))
	}

	get publicLink(): string {
		return `${window.location.origin}/reserver/${btoa(this.event.id.toString())}`
	}

	async askRemoval(slot: ReservationSlotDto) {
		if (confirm("Voulez-vous annuler cette réservation ?")) {
			await firstValueFrom(this.eventService.cancelBooking(slot.id))
			await this.ngOnInit();
		}
	}
}
