import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { InvoiceDto } from "../../shared/dtos/invoice/invoice.dto";
import { InvoiceService } from "../../shared/services/invoice.service";
import { PageResponse } from '../../shared/dtos/page-response.dto';
import { InvoiceRequestParams } from "../../shared/models/request.params";


@Injectable({ providedIn: 'root' })
export class InvoicesResolver {

    constructor(private invoiceService: InvoiceService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<PageResponse<InvoiceDto>> {
		return this.invoiceService.getByFilters(route.queryParams as InvoiceRequestParams)
    }

}
