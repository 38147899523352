<section *ngIf="event">
	<header>
		<h1>{{event.activity.title}}</h1>
		<h6>{{event.clientName}}</h6>
		<h4><a [href]="publicLink" target="_blank">Lien public</a></h4>
	</header>

	<div id="calendar">
		<app-public-calendar mode="PRIVATE" [event]="event" (select)="askRemoval($event)"></app-public-calendar>
	</div>
</section>
