import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { BookingService } from "../../shared/services/booking.service";
import { DocumentService } from "../../shared/services/document.service";


@Injectable({ providedIn: 'root' })
export class DocumentsResolver {

    constructor(private documentService: DocumentService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
		return this.documentService.getDocumentsByBooking(route.parent.params.id)
    }
}
