<section class="container-fluid">
	<div class="header">
		<h1>Tableau de bord</h1>

		<form class="btn-group" [formGroup]="form" *ngIf="form">

			<button class="button is-primary" (click)="exportReport()">
				<mat-icon> bar_chart</mat-icon>
				<span>Exporter rapport CA</span>
			</button>

			<!-- <button class="button is-info" (click)="exportStats()">
				<mat-icon>cloud_download</mat-icon>
				<span>Exporter données filtrées</span>
			</button> -->

			<div class="custom-field" style="max-width: 280px; width: 280px;">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Zone géographique</mat-label>
					<mat-select formControlName="zoneId" (selectionChange)="zoneChanged()">
						<mat-option [value]="0">Toutes les zones</mat-option>
						<mat-option *ngFor="let zone of userService.currentUser().zones" [value]="zone.id">
							{{zone.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<!-- <div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Région</mat-label>
					<mat-select formControlName="regionId" (selectionChange)="regionChanged()">
						<mat-option [value]="0">Toutes les régions</mat-option>
						<mat-option *ngFor="let region of userService.currentUser().regionalCommittees" [value]="region.id">
							{{region.title}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div> -->
			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>A partir de</mat-label>
					<input matInput required
						formControlName="startAt"
						[matDatepicker]="startPicker"
						placeholder="Date"
						(dateChange)="reloadContent()"
						[max]="form.get('endAt').value"
						[min]="START_LIMIT"
					>
					<mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
					<mat-datepicker #startPicker></mat-datepicker>
				</mat-form-field>
			</div>
			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Jusqu'à</mat-label>
					<input matInput required
						formControlName="endAt"
						[matDatepicker]="endPicker"
						placeholder="Date"
						(dateChange)="reloadContent()"
						[max]="today"
						[min]="form.get('startAt').value"
					>
					<mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
					<mat-datepicker #endPicker></mat-datepicker>
				</mat-form-field>
			</div>
		</form>
	</div>

	<div class="columns" id="main-frame" *ngIf="stats">
		<div class="column is-6">
			<!-- <div class="columns" style="height: 32dvh;">
				<div class="column is-5">
					<div class="box flexed" id="ca">
						<img
							src="../../assets/images/logo-jmactiv.png"
							alt="JMActiv Entreprise"
							id="app-icon"
						/>
						<div>
							<div style="margin-bottom: 0.5rem;">Chiffre d'Affaire prévisionnel: <span style="font-size: 18px; padding: 0.5rem" class="bold">{{stats.expectedCa}}</span></div>
							<div style="margin-bottom: 0.5rem;">Chiffre d'Affaire à date: <span style="padding: 0.5rem" class="bold">{{stats.realCa}}</span></div>
							<div style="margin-bottom: 0.5rem;">Nb affaires conclues: <span class="bold">{{stats.totalSuccessContracts}}</span></div>

							<div>Taux de transformation:
								<span class="bold">{{stats.closingRate}}</span>
								<mat-icon
									style="margin-left:6px; margin-top: 2px; width: 12px !important; height: 12px !important; font-size: 12px !important"
									tooltipStr="Un devis est considéré comme non transformé s'il n'est pas accepté après 30 jours à compter de la date d'envoi">
									help_outline
								</mat-icon>
							</div>
						</div>
					</div>
				</div>
				<div class="column">
					<div class="box flexed">
						<div class="bold">Chiffre d'affaire mensuel</div>
						<div id="canvas-container">
							<canvas #chart></canvas>
						</div>
					</div>
				</div>
			</div> -->
			<!-- <div class="columns">
				<div class="column">
					<div class="box flexed" [ngClass]="{'clickable': stats.invoices.length}" (click)="goInvoicePage()">
						<div class="bold" style="padding: 0.5rem">Factures</div>
						<div class="scrollable">
							<table class="table is-striped is-fullwidth" *ngIf="stats.invoices.length">
								<thead>
									<tr>
										<th>Ref</th>
										<th>Date de paiement</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let i of stats.invoices">
										<td>{{i.reference}}</td>
										<td>{{i.paymentDate ? helper.dateFr(i.paymentDate): "-"}}</td>
									</tr>
								</tbody>
							</table>
							<div class="no-data" *ngIf="!stats.invoices.length">
								<p>Aucune facture sur la période</p>
							</div>
						</div>
					</div>
				</div>
				<div class="column">
					<div class="box flexed">
						<div class="bold" style="padding: 0.5rem">Top activités sur la période</div>
						<div class="scrollable">
							<table class="table is-striped is-fullwidth" *ngIf="stats.activityScore.length">
								<thead>
									<tr>
										<th>Activité</th>
										<th>Nb ventes</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let a of stats.activityScore">
										<td>{{a.activityTitle}}</td>
										<td>{{a.score}}</td>
									</tr>
								</tbody>
							</table>
							<div class="no-data" *ngIf="!stats.activityScore.length">
								<p >Aucune donnée sur la période</p>
							</div>
						</div>
					</div>
				</div>
			</div> -->

			<div class="columns">
				<div class="column is-3">
					<div class="box">
						<h5>{{stats.totalContractsNumber}} Devis réalisés</h5>
						<h4>{{stats.totalContractsAmount}} <span style="font-size: 0.75rem;">HT</span></h4>
					</div>
				</div>
				<div class="column is-3" >
					<div class="box green">
						<h5>{{stats.acceptedContractsNumber}} Devis acceptés </h5>
						<h4>{{stats.acceptedContractsAmount}} <span style="font-size: 0.75rem;">HT</span></h4>
					</div>
				</div>
				<div class="column is-3">
					<div class="box blue">
						<h5>{{stats.awaitingContractsNumber}} Devis en attente</h5>
						<h4>{{stats.awaitingContractsAmount}} <span style="font-size: 0.75rem;">HT</span></h4>
					</div>
				</div>
				<div class="column is-3">
					<div class="box red">
						<h5>{{stats.rejectedContractsNumber}} Devis refusés</h5>
						<h4>{{stats.rejectedContractsAmount}} <span style="font-size: 0.75rem;">HT</span></h4>
					</div>
				</div>

			</div>
			<div class="columns">
				<div class="column is-3">
					<div class="box">
						<h5>CA à date</h5>
						<h4>{{stats.realCa}} <span style="font-size: 0.75rem;">HT encaissé</span></h4>
					</div>
				</div>
				<div class="column is-3">
					<div class="box">
						<h5>TVA Collectée à date</h5>
						<h4>{{stats.vat}}</h4>
					</div>
				</div>
				<div class="column is-3">
					<div class="box clickable" (click)="goInvoicePage()">
						<h5>{{stats.awaitingInvoicesNumber}} facture<span *ngIf="stats.awaitingInvoicesNumber > 1">s</span>&nbsp;<span style="font-size: 0.75rem;">en att. de règlement</span></h5>
						<h4>{{stats.awaitingInvoicesAmount}} HT</h4>
					</div>
				</div>
				<div class="column is-3">
					<div class="box">
						<h5>Taux de transformation</h5>
						<h4>
							{{stats.closingRate}}
							<mat-icon
								style="margin-left:6px; margin-top: 2px; width: 12px !important; height: 12px !important; font-size: 12px !important"
								tooltipStr="Un devis est considéré comme non transformé s'il n'est pas accepté après 30 jours à compter de la date d'envoi">
								help_outline
							</mat-icon>
						</h4>
					</div>
				</div>
			</div>
			<div class="columns">
				<div class="column">
					<div class="box" style="height: 36dvh">
						<h5 style="margin: 1rem 0 2rem 0.5rem">Progression du CA HT</h5>
						<canvas #chart style="width: 100%; padding-bottom: 62px;"></canvas>
					</div>
				</div>
			</div>

		</div>
		<div class="column">
			<div class="box">
				<!-- REGION VIEW -->
				<app-table-creator
					[config]="REGION_TABLE_CONFIG"
					[dataSource]="stats.regionStats"
				></app-table-creator>
				<!-- DEPARTMENT VIEW -->
				<!-- <app-table-creator
					*ngIf="form.get('zoneId').value !== 0 || form.get('regionId').value !== 0"
					[config]="DEPARTMENT_TABLE_CONFIG"
					[dataSource]="stats.departmentStats"
				></app-table-creator> -->
			</div>
		</div>
	</div>

</section>
