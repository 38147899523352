import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { CommercialProposalDto } from 'src/app/modules/shared/dtos/commercial-proposal/commercial-proposal.dto';
import { InvoiceDto } from 'src/app/modules/shared/dtos/invoice/invoice.dto';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { INVOICE_STATUS_OBJ } from './../../../../shared/enums/invoice-status.enum';
import { InvoiceModal } from '../../../modals/invoice-modal/invoice.modal';
import { saveAs as importedSaveAs } from 'file-saver';
import { InvoiceService } from 'src/app/modules/shared/services/invoice.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrl: './invoice.component.scss'
})

export class InvoiceComponent implements OnInit, OnDestroy {

	readonly INVOICE_STATUS_OBJ = INVOICE_STATUS_OBJ;
	proposal: CommercialProposalDto;
	invoices: InvoiceDto[];

	private subscriptions: Subscription[] = [];

	constructor(
		public helper: HelperService,
		private activatedRoute: ActivatedRoute,
		private dialog: MatDialog,
		private invoiceService: InvoiceService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.activatedRoute.data.subscribe(data => {
				this.proposal = data.proposal;
				this.invoices = _.orderBy(data.invoices.content, (invoice) => this.helper.getNumber(invoice.reference));
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	openInvoice(invoice?: InvoiceDto) {
		this.dialog.open(InvoiceModal, {
			data: {
				proposal: this.proposal,
				invoice
			}
		})
		this.subscriptions.push(
			this.dialog.afterAllClosed.subscribe(() => {
				this.router.navigate([], {
					onSameUrlNavigation: 'reload'
				})
			})
		)
	}

	async pdf(invoice: InvoiceDto) {
		const pdf = await firstValueFrom(this.invoiceService.generateInvoicePdf(invoice.id));
		importedSaveAs(pdf.body, invoice.reference);
	}
}
