import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { BookingMinimalDto } from '../../shared/dtos/booking/booking-minimal.dto';
import { PageResponse } from "../../shared/dtos/page-response.dto";
import { BookingService } from "../../shared/services/booking.service";
import { UserService } from "../../shared/services/user.service";
import { ROLES } from "../../shared/enums/roles.enum";


@Injectable({ providedIn: 'root' })
export class BookingsResolver {

    constructor(
		private bookingService: BookingService,
		private userService: UserService
	) {}

    resolve(route: ActivatedRouteSnapshot): Observable<PageResponse<BookingMinimalDto>> {
		return this.bookingService.getBookings(
			this.userService.currentUser().role === ROLES.CLIENT
				? {limit: 999999, clientId: this.userService.currentUser().id }
				: route.queryParams
			)
    }

}
