import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RegionalCommitteeBaseDto } from "../dtos/regional-comittee.dto";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { DepartmentDto } from "../dtos/department.dto";


@Injectable({
	providedIn: 'root'
})
export class JmaService {

	private readonly HTTP_OPTIONS = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Credentials': 'true',
			'Access-Control-Allow-Headers': 'Content-Type',
			'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE'
		})
	};

	constructor(private http: HttpClient) { }

	getAllComiteRegionals(): Observable<RegionalCommitteeBaseDto[]> {
		return this.http.get<RegionalCommitteeBaseDto[]>(`${environment.urlApi}/public/regions`, this.HTTP_OPTIONS);
	}

	getDepartments(regionId?: number): Observable<DepartmentDto[]> {
		let params = new HttpParams();
		if (regionId)
			params = params.append('regionId', regionId);
		return this.http.get<DepartmentDto[]>(`${environment.urlApi}/public/departements`, {params});

	}

	getRegionalCommitteesByActivityId(activityId: number): Observable<RegionalCommitteeBaseDto[]> {
		return this.http.get<RegionalCommitteeBaseDto[]>(`${environment.urlApi}/comiteByActivity/${activityId}`, this.HTTP_OPTIONS);
	}

	toggleActivityForComiteRegional(comiteRegionalId: number): Observable<any> {
		return this.http.post<any>(`${environment.urlApi}/toggleActivity/${comiteRegionalId}`, this.HTTP_OPTIONS);
	}

	addActivityToComiteRegional(comiteId: number, activityId: number): Observable<any> {
		return this.http.post(`${environment.urlApi}/addActivityToComite/${comiteId}/${activityId}`, {}, this.HTTP_OPTIONS);
	}

	removeActivityFromComiteRegional(comiteId: number, activityId: number): Observable<any> {
		return this.http.delete(`${environment.urlApi}/removeActivityFromComite/${comiteId}/${activityId}`, this.HTTP_OPTIONS);
	}
}
