<div class="container is-max-widescreen">
	<h1>Nouvelle réservation</h1>

	<!-- BUTTONS -->
	<div class="buttons">
		<a routerLink="/dashboard/catalogue">
			<mat-icon>add</mat-icon>
			Ajouter d'autres activités à ma sélection
		</a>
	</div>

	<p *ngIf="bookingService.booking().length === 0">Vous n'avez sélectionné aucune activité.</p>

	<!-- EVENT LIST -->
	<div class="booking" *ngFor="let bookingEvent of bookingService.booking(); let eventIndex = index;">
		<div class="columns is-vcentered">
			<div class="column is-3">
				<app-tile [animate]="false" [imageUrl]="bookingEvent.activity.image"
					[title]="bookingEvent.activity.title" [smallText]="true" aspectRatio="8/5">
				</app-tile>
			</div>
			<div class="column event-form">
				<!-- LINE 1 -->
				<div class="top">
					<mat-form-field>
						<mat-label>Format souhaité</mat-label>
						<mat-select required [(ngModel)]="bookingEvent.offer">
							<mat-option *ngFor="let o of bookingEvent.activity.offers" [value]="o">
								<span>{{o.sessionConditions}}</span>
								<span
									*ngIf="bookingEvent.activity.activityType != ACTIVITY_TYPES.AUTRE && bookingEvent.activity.activityType != ACTIVITY_TYPES.FICHE">
									({{(o.sessionDuration / 60).toFixed(2).replace('.', 'h')}})</span>
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<!-- LINE 2 -->
				<div class="bottom">
					<div class="columns is-vcentered is-gapless">
						<!-- DATE -->
						<div class="column is-3">
							<mat-form-field subscriptSizing="dynamic">
								<mat-label>Date souhaitée</mat-label>
								<input matInput required [(ngModel)]="bookingEvent.date" [matDatepicker]="datepicker"
									placeholder="Date de fin" [min]="bookingService.nextAvailableDate"
									[matDatepickerFilter]="weekendsDatesFilter">
								<mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
								<mat-datepicker #datepicker></mat-datepicker>
							</mat-form-field>
						</div>
						<!-- IS NEXT AVAILABLE DATE -->
						<div class="column is-4"
							*ngIf="!appService.isMobile() || (appService.isMobile() && isNextAvailableDate(bookingEvent.date))">
							<div class="date-info" *ngIf="isNextAvailableDate(bookingEvent.date)">
								<svg class="icon-loader-check" x="0px" y="0px" width="471.197px" height="471.197px"
									viewBox="0 0 510 510" overflow="inherit" preserveAspectRatio="xMidYMid meet">
									<g id="loader">
										<circle class="circle" fill="transparent" stroke="#41BD59" stroke-width="48"
											stroke-linecap="round" stroke-miterlimit="10" cx="250" cy="250"
											r="212.599" />
										<polyline class="check" fill="none" stroke="#41BD59" stroke-width="32"
											stroke-linecap="round" stroke-linejoin="round"
											points="227.599,322.099 290.599,259.099 180.599,149.099 " />
									</g>
								</svg>
								<span>Prochaine date disponible</span>
							</div>
						</div>
						<!-- BUTTONS -->
						<div class="column">
							<div class="event-buttons">
								<button class="button is-primary is-inverted"
									[ngClass]="{'is-fullwidth' : appService.isMobile() }"
									(click)="duplicate(bookingEvent)">
									Doubler cette activité
								</button>

								<button class="button is-white" [ngClass]="{'is-fullwidth' : appService.isMobile() }"
									(click)="bookingService.booking().splice(eventIndex, 1)">
									Retirer
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<!-- CUSTOMER -->
	<form id="customer" [formGroup]="form" *ngIf="userService.currentUser().role !== ROLES.CLIENT && !!form">
		<h3>Choix du client</h3>
		<div class="columns">
			<div class="column is-3">
				<mat-form-field>
					<mat-label>Région du client</mat-label>
					<mat-select formControlName="regionId" (selectionChange)="regionChanged()">
						<mat-option [value]="0">Toutes les régions</mat-option>
						<mat-option *ngFor="let r of userService.currentUser().regionalCommittees" [value]="r.id">
							{{r.title}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="column">
				<mat-form-field>
					<mat-label>Client</mat-label>
					<input matInput type="text" [matAutocomplete]="auto" formControlName="customerSearch" >
					<mat-autocomplete
						#auto="matAutocomplete"
						[displayWith]="clientDisplayName"
						(optionSelected)="updateCustomer($event)"
					>
						<mat-option *ngFor="let customer of filteredCustomers" [value]="customer">
							<div class="is-flex is-align-items-center">
								<mat-label style="flex:1">{{clientDisplayName(customer)}}</mat-label>
							</div>
						</mat-option>
						<mat-option [disabled]="true" *ngIf="filteredCustomers?.length === 0">
							Aucun résultat
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>
		</div>
	</form>

	<!-- MESSAGE -->
	<div id="message" *ngIf="bookingService.booking().length > 0">
		<h3>Dites nous en plus sur vos envies</h3>
		<mat-form-field>
			<textarea matInput [(ngModel)]="bookingService.message" rows="12"></textarea>
		</mat-form-field>
	</div>

	<!-- BUTTONS -->
	<div class="buttons">
		<a routerLink="/dashboard/catalogue" style="margin: 2rem 0;">
			<mat-icon>add</mat-icon>
			Ajouter {{ bookingService.booking().length === 0 ? "des" : "d'autres" }} activités à ma sélection
		</a>
		<button class="button is-primary is-large" *ngIf="bookingService.booking().length > 0"
			[disabled]="userService.currentUser().role !== ROLES.CLIENT && bookingService.customerId() === 0"
			(click)="bookingService.submitDemand()">
			Envoyer ma demande de réservation
		</button>

		<h6 *ngIf="userService.currentUser().role !== ROLES.CLIENT && bookingService.customerId() === 0">
			Veuillez choisir le client pour lequel vous réalisez la demande de réservation.
		</h6>
	</div>
</div>
