import { Component, HostListener } from '@angular/core';
import { AppService } from './modules/shared/services/app.service';


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.appService.windowResized()
	}

	constructor(
		public appService: AppService
	) { }

	ngOnInit(): void {
	}

	ngOnDestroy(): void {
	}

}
