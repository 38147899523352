import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFr from '@angular/common/locales/fr';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { provideNgxMask } from 'ngx-mask';
import { AppComponent } from './app.component';
import { appInitializer } from './app.initializer';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { DashboardModule } from './modules/dashboard/dashboard.module';
import { PublicModule } from './modules/public/public.module';
import { AuthGuard } from './modules/shared/guards/auth.guard';
import { ErrorInterceptor } from './modules/shared/interceptors/error.interceptor';
import { LoaderInterceptor } from './modules/shared/interceptors/loader.interceptor';
import { TokenInterceptor } from './modules/shared/interceptors/token.interceptor';
import { AuthService } from './modules/shared/services/auth.service';
import { SharedModule } from './modules/shared/shared.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { getFrenchPaginatorIntl } from './modules/shared/adapters/paginator.adapter';
import { PublicComponent } from './modules/public/public.component';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		RouterModule.forRoot([
			{
				path: '',
				component: PublicComponent,
				loadChildren: () => import('./modules/public/public.module').then(() => PublicModule)
			},
			{
				path: 'dashboard',
				component: DashboardComponent,
				canActivate: [AuthGuard],
				loadChildren: () => import('./modules/dashboard/dashboard.module').then(() => DashboardModule)
			},
			{
				path: '**',
				redirectTo: ''
			}
		], {
			scrollPositionRestoration: 'top', //works only for mobiles, check app.component & app.service for a desktop workaround
			onSameUrlNavigation: 'reload',
		}),
		SharedModule,
	],
	providers: [
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
		{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { maxHeight: '90vh' }},
		{ provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService, Router] },
		{ provide: LOCALE_ID, useValue: 'fr-FR' },
		{ provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
		{ provide: MatPaginatorIntl, useValue: getFrenchPaginatorIntl() },
		provideNgxMask()
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
