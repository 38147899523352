
import { DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { firstValueFrom } from 'rxjs';
import { CommuneDto } from 'src/app/modules/shared/dtos/commune.dto';
import { DepartmentDto } from 'src/app/modules/shared/dtos/department.dto';
import { RegionalCommitteeBaseDto } from 'src/app/modules/shared/dtos/regional-comittee.dto';
import { UserDto } from 'src/app/modules/shared/dtos/user/user.dto';
import { ROLES } from 'src/app/modules/shared/enums/roles.enum';
import { JOBS } from 'src/app/modules/shared/models/jobs.list';
import { GeoService } from 'src/app/modules/shared/services/geo.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { JmaService } from 'src/app/modules/shared/services/jma.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { accountDoesntExistsValidator } from 'src/app/modules/shared/validators/account-doesnt-exists.validator';
import { CONSTANTS } from 'src/constants';

@Component({
	selector: 'app-utilisateur.modal',
	templateUrl: './utilisateur.modal.html',
	styleUrls: ['./utilisateur.modal.scss'],
})

export class UtilisateurModal implements OnInit {

	@ViewChild('cityField', { read: MatAutocompleteTrigger }) cityField: MatAutocompleteTrigger;

	readonly JOBS = JOBS;
	readonly ROLES = ROLES;
	readonly CLIENT_REQUIRED_FIELDS =  ['address', 'zipCode', 'city', 'companyName', 'function', 'regionId', 'departmentId'];

	mode: 'EDIT' | 'NEW';
	communes: CommuneDto[] = [];
	form!: FormGroup;
	resForm!: FormGroup;
	comiteRegionals: RegionalCommitteeBaseDto[] = [];
	departments: DepartmentDto[] = [];
	emailExists: boolean = false;
	isArchived: boolean;

	constructor(
		public dialogRef: DialogRef,
		private formBuilder: FormBuilder,
		private utilisateurService: UserService,
		private jmaService: JmaService,
		private geoService: GeoService,
		private helper: HelperService,
		private cdr: ChangeDetectorRef
	) { }

	async ngOnInit() {
		this.comiteRegionals = await firstValueFrom(this.jmaService.getAllComiteRegionals());
		if (!!this.dialogRef.config.data.userId) {
			this.mode = 'EDIT';
			const user = await firstValueFrom(this.utilisateurService.getById(this.dialogRef.config.data.userId));
			this.initForm(user);
			if (user.role === ROLES.CLIENT) {
				this.departments = await firstValueFrom(this.jmaService.getDepartments(this.form.get('regionId').value));
			}
		} else {
			this.mode = 'NEW';
			this.initForm();
			this.form.get('email').addAsyncValidators([accountDoesntExistsValidator(this.utilisateurService)])
		}
	}

	codePostalChanged() {
		setTimeout(async () => {
			if (this.form.get('postalCode').valid) {
				const cp = this.form.get('postalCode').value;
				const communes = await firstValueFrom(this.geoService.getCommunesByCodePostal(cp));
				this.communes = this.helper.orderBy(communes, 'nom');

				if (this.communes.length > 0) {
					this.form.get('city').patchValue(this.communes[0].nom);
				}
			}
		}, 0)
	}

	roleChanged() {
		this.form.get('role').value === ROLES.CLIENT
			? this.makeClientFieldsRequired()
			: this.makeClientFieldsNotRequired()
		this.form.markAllAsTouched();
	}

	async regionChanged() {
		this.form.get('departmentId').patchValue(null);
		this.departments = await firstValueFrom(this.jmaService.getDepartments(this.form.get('regionId').value));
	}

	async onSubmit() {
		Object.keys(this.form.controls).forEach(key => {
			// Get errors of every form control
			console.log(key, this.form.get(key).errors);
		  });

		if (this.form.valid) {
			this.mode === 'EDIT'
				? await firstValueFrom(this.utilisateurService.updateUser(this.form.getRawValue()))
				: await firstValueFrom(this.utilisateurService.createUser(this.form.getRawValue()))
			this.close();
		}
	}

	async toggleArchived() {
		if (confirm(CONSTANTS.YOU_SURE)) {
			this.isArchived = await firstValueFrom(this.utilisateurService.toggleIsArchived(this.form.get('id').value));
		}
	}

	close() {
		this.dialogRef.config.data.saveCloseCallBack();
		this.dialogRef.close();
	}

	private initForm(user?: UserDto) {
		this.form = this.formBuilder.group({
			role: [!!user ? user.role : ROLES.CLIENT],
			lastName: [!!user ? user.lastName : '', [Validators.required]],
			firstName: [!!user ? user.firstName : '', [Validators.required]],
			email: [!!user ? user.email : '', [Validators.required, Validators.email]],
			phone: [!!user ? user.phone : '', [Validators.required, Validators.pattern('^[0-9]{10}$'), Validators.minLength(10)]],
			address: [!!user ? user.address : '', Validators.required],
			addressLineTwo: [!!user ? user.addressLineTwo : ''],
			zipCode: [!!user ? user.zipCode : '', [Validators.required, Validators.pattern('^[0-9]{5}$'), Validators.minLength(5)]],
			city: [!!user ? user.city : '', Validators.required],
			companyName: [!!user ? user.companyName : '', Validators.required],
			function: [!!user ? user.function : '', Validators.required],
			strate: [!!user ? user.strate : ''],
			regionId: [!!user ? user.role === ROLES.CLIENT ? user.regionalCommittees[0].id : null : null, Validators.required],
			departmentId: [!!user ? user.role === ROLES.CLIENT ? user.department.id : null : null, Validators.required],
			billingEmail: [!!user ? user.billingEmail : '', [Validators.email]]
		})

		if (!!user) {
			this.form.addControl('id', new FormControl(user.id))
			this.isArchived = user.archived;
		}

		this.form.markAsTouched();
		this.cdr.detectChanges();
	}


	private makeClientFieldsRequired() {
		this.form.get('regionId').patchValue(null);
		this.form.get('departmentId').patchValue(null);
		this.CLIENT_REQUIRED_FIELDS.forEach(f => {
			this.form.get(f).addValidators(Validators.required);
			this.form.get(f).updateValueAndValidity();
		})
		this.form.get('city').patchValue("");

	}
	private makeClientFieldsNotRequired() {
		this.CLIENT_REQUIRED_FIELDS.forEach(f => {
			this.form.get(f).removeValidators(Validators.required);
			this.form.get(f).updateValueAndValidity();
		})
		this.form.get('city').patchValue("Ivry-Sur-Seine");
	}
}
