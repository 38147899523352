import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreateAdminDto, CreateClientDto } from '../dtos/user/create-user.dto';
import { UpdateMyInformationsDto, UpdateUserDto } from '../dtos/user/update-user.dto';
import { ROLES } from '../enums/roles.enum';
import { RegionalCommitteeBaseDto } from '../dtos/regional-comittee.dto';
import { ExistsDto } from '../dtos/exists.dto';
import { ClientRequestParams } from '../models/request.params';
import { PageResponse } from '../dtos/page-response.dto';
import { UserMinimalDto } from '../dtos/user/user.minimal.dto';
import { HelperService } from './helper.service';
import { UserDto } from '../dtos/user/user.dto';


@Injectable({
	providedIn: 'root'
})
export class UserService {

	currentUser = signal<UserDto | null>(null);
	currentRegion = signal<RegionalCommitteeBaseDto | null>(null);
	isLoggedIn = computed(() => !!this.currentUser())
	isFederalAdmin = computed(() => this.currentUser()?.role === ROLES.ADMIN_FEDE);

	constructor(
		private http: HttpClient,
		private helperService: HelperService
	) { }

	register(dto: CreateClientDto): Observable<UserDto> {
		return this.http.post<UserDto>(`${environment.urlApi}/public/register`, dto);
	}

	createUser(dto: CreateClientDto | CreateAdminDto): Observable<UserDto> {
		return this.http.post<UserDto>(`${environment.urlApi}/user/create-user`, dto);
	}

	updateMyInformations(dto: UpdateMyInformationsDto): Observable<UserDto> {
		return this.http.put<UserDto>(`${environment.urlApi}/user/${dto.id}/my-informations`, dto);
	}

	updateUser(dto: UpdateUserDto): Observable<UserDto> {
		return this.http.put<UserDto>(`${environment.urlApi}/user/${dto.id}`, dto);
	}

	getById(userId: number): Observable<UserDto> {
		return this.http.get<UserDto>(`${environment.urlApi}/user/${userId}`);
	}

	emailAlreadyExists(email: string): Observable<ExistsDto> {
		return this.http.post<ExistsDto>(`${environment.urlApi}/public/check-email`, { email });
	}

	changePassword(newPassword: string) {
		return this.http.put(`${environment.urlApi}/user/update-password/${newPassword}`, null);
	}

	getMyCustomers(regionId?: number) {
		let params = new HttpParams();
		if (!!this.currentRegion() || regionId) {
			params = params.append('regionId', this.currentRegion()?.id || regionId);
		}
		return this.http.get<UserDto[]>(`${environment.urlApi}/user/my-customers`, {params})
	}

	getByFilters(params: ClientRequestParams) {
		return this.http.get<PageResponse<UserMinimalDto>>(
			`${environment.urlApi}/user/by-filters`,
			{ params: this.helperService.getObjectAsHttpParams(params) }
		)
	}

	toggleIsArchived(userId: number): Observable<boolean> {
		return this.http.put<boolean>(`${environment.urlApi}/user/${userId}/toggle-archived`, null)
	}
}
