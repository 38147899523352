<div id="modal-container">
    <h2 style="text-align: center; margin-bottom: 2rem;">Sélectionnez la zone</h2>
	<h3>{{params.modalTitle}}</h3>

    <div id="cropper-container">
        <div id="cropper" [ngClass]="{'small': imageSourceSize.height > imageSourceSize.width }">
            <image-cropper  [imageChangedEvent]="params.inputImage"
                            [maintainAspectRatio]="params.maintainAspectRatio"
                            format="jpeg"
                            [aspectRatio]="params.targetWidth / params.targetHeight"
                            [resizeToWidth]="params.targetWidth"
                            [resizeToHeight]="params.targetHeight"
                            [cropperMinWidth]="params.targetWidth"
                            [cropperMinHeight]="params.targetHeight"
                            (imageCropped)="imageCropped($event)"
                            (imageLoaded)="imageLoaded($event)"
                            (cropperReady)="cropperReady()"
                            (loadImageFailed)="loadImageFailed()"></image-cropper>
        </div>
    </div>

    <div class="buttons">
        <button class="button" (click)="dialogRef.close()">Annuler</button>
      	<button class="button is-primary" (click)="ok()">Soumettre</button>
    </div>
</div>
