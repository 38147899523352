
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommercialProposalDto } from '../dtos/commercial-proposal/commercial-proposal.dto';
import { ProposalLineDto, ProposalLineUpdateDto } from '../dtos/commercial-proposal/proposal-line.dto';
import { PaymentCondition } from '../dtos/payment-conditions.dto';

@Injectable({
	providedIn: 'root'
})
export class CommercialProposalService {

	constructor(
		private http: HttpClient
	) { }

	getPaymentConditions(): Observable<PaymentCondition[]> {
		return this.http.get<PaymentCondition[]>(`${environment.urlApi}/proposal/payment-conditions`)
	}

	getByBookingId(bookingId: number): Observable<CommercialProposalDto> {
		return this.http.get<CommercialProposalDto>(`${environment.urlApi}/proposal/${bookingId}`);
	}

	getPdfProposalById(proposalId: number): Observable<HttpResponse<Blob>> {
		return this.http.get<Blob>(
			`${environment.urlApi}/proposal/pdf/${proposalId}`,
			{
				headers: new HttpHeaders({'Content-Type': 'application/pdf'}),
				observe: 'response',
				responseType: 'blob' as 'json'
			}
		);
	}

	updateProposalLine(proposalId: number, dto: ProposalLineUpdateDto): Observable<ProposalLineDto> {
		return this.http.put<ProposalLineDto>(
			`${environment.urlApi}/proposal/${proposalId}/${dto.id}`,
			dto
		)
	}

	updateApplicationFees(proposalId: number, applicationFees: number): Observable<any> {
		return this.http.put(
			`${environment.urlApi}/proposal/${proposalId}/update-application-fees`,
			{ applicationFees }
		)
	}

	toggleVAT(proposalId: number, applyVAT: boolean) : Observable<any> {
		return this.http.put(
			`${environment.urlApi}/proposal/${proposalId}/toggle-vat`,
			{ applyFee: applyVAT }
		)
	}

	togglePaymentFee(proposalId: number, applyFee: boolean) : Observable<any> {
		return this.http.put(
			`${environment.urlApi}/proposal/${proposalId}/toggle-payment-fee`,
			{ applyFee }
		)
	}

}
