import { DialogRef } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { CommercialProposalDto } from 'src/app/modules/shared/dtos/commercial-proposal/commercial-proposal.dto';
import { ProposalLineDto } from 'src/app/modules/shared/dtos/commercial-proposal/proposal-line.dto';
import { InvoiceDto } from 'src/app/modules/shared/dtos/invoice/invoice.dto';
import { INVOICE_STATUS, INVOICE_STATUS_OBJ } from 'src/app/modules/shared/enums/invoice-status.enum';
import { EPaymentType, PAYMENT_TYPES } from 'src/app/modules/shared/enums/payment-type.enum';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { InvoiceService } from 'src/app/modules/shared/services/invoice.service';
import { CONSTANTS } from 'src/constants';


@Component({
	selector: 'app-invoice.modal',
	templateUrl: './invoice.modal.html',
	styleUrls: ['./invoice.modal.scss'],
})

export class InvoiceModal implements OnInit {

	readonly EPaymentType = EPaymentType;
	readonly PAYMENT_TYPES = Object.keys(PAYMENT_TYPES).map((key, index) => {
		return {
			order: PAYMENT_TYPES[key].order,
			label: PAYMENT_TYPES[key].label,
			value: key
		}
	}).sort((optionA, optionB) => optionA.order > optionB.order ? 1 : -1)

	readonly INVOICE_STATUS_OBJ = INVOICE_STATUS_OBJ;
	readonly INVOICE_STATUS_ARRAY = Object.keys(INVOICE_STATUS_OBJ).map((key, index) => {
		return {
			order: INVOICE_STATUS_OBJ[key].order,
			label: INVOICE_STATUS_OBJ[key].label,
			value: key
		}
	}).sort((optionA, optionB) => optionA.order > optionB.order ? 1 : -1)

	readonly INVOICE_STATUS = INVOICE_STATUS;

	form: FormGroup;
	proposal: CommercialProposalDto;
	eventOptions: ProposalLineDto[];
	invoice: InvoiceDto;

	constructor(
		public helper: HelperService,
		public dialogRef: DialogRef,
		private router: Router,
		private formBuilder: FormBuilder,
		private invoiceService: InvoiceService
	) { }

	async ngOnInit() {
		this.proposal = this.dialogRef.config.data.proposal;
		this.invoice = this.dialogRef.config.data.invoice;
		this.initForm();
		this.eventOptions = this.proposal.lines.sort((a, b) => a.id > b.id ? 1 : -1);
		this.toggleAll();
	}

	goProposal() {
		if (confirm(CONSTANTS.YOU_SURE)) {
			this.dialogRef.close();
			this.router.navigateByUrl('/dashboard/demandes/'+this.proposal.bookingId+'/devis');
		}
	}

	updatePaymentLinkValidator() {
		if (this.form.get('paymentType').value === EPaymentType.STRIPE) {
			this.form.get('paymentLink').addValidators(Validators.required);
			this.form.get('paymentLink').enable();
		} else {
			this.form.get('paymentLink').removeValidators(Validators.required);
			this.form.get('paymentLink').patchValue(null);
			this.form.get('paymentLink').disable();
		}
		this.form.get('paymentLink').updateValueAndValidity();
		this.form.get('paymentLink').markAsTouched();
	}

	updateDatesValidators() {
		if (INVOICE_STATUS_OBJ[this.form.get('status').value].order >= INVOICE_STATUS_OBJ[INVOICE_STATUS.ENVOYEE].order) {
			this.form.get('sendDate').addValidators(Validators.required);
			this.form.get('sendDate').enable();
		} else {
			this.form.get('sendDate').patchValue(null);
			this.form.get('payDate').patchValue(null);
			this.form.get('sendDate').removeValidators(Validators.required);
			this.form.get('payDate').removeValidators(Validators.required);
			this.form.get('sendDate').disable();
			this.form.get('payDate').disable();
		}

		if (INVOICE_STATUS_OBJ[this.form.get('status').value].order >= INVOICE_STATUS_OBJ[INVOICE_STATUS.PAYEE].order) {
			this.form.get('payDate').addValidators(Validators.required);
			this.form.get('payDate').enable();
		} else {
			this.form.get('payDate').patchValue(null);
			this.form.get('payDate').removeValidators(Validators.required);
			this.form.get('payDate').disable();
		}

		this.form.get('sendDate').updateValueAndValidity();
		this.form.get('sendDate').markAsTouched();
		this.form.get('payDate').updateValueAndValidity();
		this.form.get('payDate').markAsTouched();
	}

	toggleOptionId(id: number): void {
		const currentValue: number[] = this.form.get('proposalLineIds').value;
		const index = currentValue.findIndex(v => v === id);
		index > -1 ? currentValue.splice(index, 1) : currentValue.push(id);
		this.form.get('proposalLineIds').patchValue(currentValue);
	}

	toggleAll(): void {
		const ids = this.eventOptions.map(option => option.id);
		// .filter(o => !o.invoiceRef)
		this.form.get('proposalLineIds').patchValue(ids);
	}

	async onSubmit() {
		if (!this.invoice) {
			await firstValueFrom(this.invoiceService.generateInvoice(this.form.getRawValue()))
		} else {
			await firstValueFrom(this.invoiceService.updateInvoice(this.form.getRawValue()));
		}
		this.dialogRef.close();
	}

	async deleteInvoice() {
		if (confirm(CONSTANTS.YOU_SURE)) {
			await firstValueFrom(this.invoiceService.deleteInvoice(this.invoice.id));
			this.dialogRef.close();
		}
	}

	private initForm(): void {
		this.form = this.formBuilder.group({
			id: [this.invoice?.id],
			status: [this.invoice ? this.invoice.status : INVOICE_STATUS.NON_ENVOYEE],
			proposalId: [this.proposal.id, [Validators.required]],
			clientReference: [this.invoice?.clientReference],
			proposalLineIds: [
				this.proposal.lines.filter(l => l.invoiceRef === this.invoice?.reference).map(l => l.id),
				!this.invoice ? [Validators.required] : []
			],
			paymentType: [
				this.invoice?.paymentType || EPaymentType.VIREMENT,
				[Validators.required]
			],
			paymentLink: [{
				value: this.invoice?.paymentLink,
				disabled: this.invoice?.paymentType !== EPaymentType.STRIPE
			}, [Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]
			],
			sendDate: [!!this.invoice?.sendDate ? this.invoice.sendDate : null],
			payDate: [!!this.invoice?.paymentDate ? this.invoice.paymentDate : null]
		})

		this.updateDatesValidators();
	}
}
