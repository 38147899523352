import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Chart from 'chart.js/auto';
import { saveAs as importedSaveAs } from 'file-saver';
import * as moment from 'moment';
import { combineLatest, debounceTime, firstValueFrom, Subscription } from 'rxjs';
import { StatsDto } from 'src/app/modules/shared/dtos/stats/stats.dto';
import { PeriodRequestParams, StatsRequestParams } from 'src/app/modules/shared/models/request.params';
import { TextFieldType } from 'src/app/modules/shared/modules/dynamic-table/models/FieldConfig';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { StatsService } from 'src/app/modules/shared/services/stats.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { dateNoFutureValidator } from 'src/app/modules/shared/validators/date-no-future.validator';
import { CONSTANTS } from 'src/constants';
import { DynamicTableConfig } from './../../../shared/modules/dynamic-table/dynamic-table-config';
import { BOOKING_STATUS } from 'src/app/modules/shared/enums/booking-status.enum';


@Component({
  selector: 'app-statistiques',
  templateUrl: './statistiques.component.html',
  styleUrls: ['./statistiques.component.scss']
})


export class StatistiquesComponent implements OnInit, OnDestroy {

	readonly today = new Date();
	readonly START_LIMIT = new Date('2018-01-01');

	form: FormGroup;
	stats: StatsDto;
	chartObject: Chart;


	@ViewChild('chart') chart: ElementRef<HTMLCanvasElement>

	requestParams: PeriodRequestParams = {
		startAt: moment('2023-08-01', CONSTANTS.DATE_TRANSACTION_FORMAT).toDate(),
		endAt: moment('2024-07-31', CONSTANTS.DATE_TRANSACTION_FORMAT).toDate()
	};

	REGION_TABLE_CONFIG: DynamicTableConfig = {
        columns: [
			{
				sortable: true,
				title: 'Région',
				fieldName: 'region',
				type: new TextFieldType()
			}, {
				sortable: true,
				title: 'CA prévisionnel',
				fieldName: 'expectedCa',
				maxWidth: '100px',
				type: new TextFieldType()
			}, {
				sortable: true,
				title: 'CA réel',
				fieldName: 'realCa',
				maxWidth: '100px',
				type: new TextFieldType()
			}, {
				sortable: true,
				title: 'Nb devis',
				fieldName: 'nbContracts',
				maxWidth: '100px',
				type: new TextFieldType()
			}, {
				sortable: true,
				title: 'Activités / devis',
				maxWidth: '126px',
				fieldName: 'activityAverage',
				type: new TextFieldType()
			}, {
				sortable: true,
				title: 'Panier moyen',
				maxWidth: '100px',
				fieldName: 'priceAverage',
				type: new TextFieldType()
			},
			// {
			// 	sortable: true,
			// 	title: '% réduction',
			// 	maxWidth: '100px',
			// 	fieldName: 'discountPercentage',
			// 	type: new TextFieldType()
			// }
        ],
        defaultSort: {
            name: "region",
            direction: "asc"
        },
        loadFunc: null
    };

	// DEPARTMENT_TABLE_CONFIG: DynamicTableConfig = {
    //     columns: [
	// 		{
	// 			sortable: true,
	// 			title: 'Département',
	// 			fieldName: 'departmentTitle',
	// 			type: new TextFieldType()
	// 		}, {
	// 			sortable: true,
	// 			title: 'CA prévisionnel',
	// 			fieldName: 'expectedCa',
	// 			maxWidth: '100px',
	// 			type: new TextFieldType()
	// 		}, {
	// 			sortable: true,
	// 			title: 'CA réel',
	// 			fieldName: 'realCa',
	// 			maxWidth: '100px',
	// 			type: new TextFieldType()
	// 		}, {
	// 			sortable: true,
	// 			title: 'Nb affaires',
	// 			fieldName: 'nbContracts',
	// 			maxWidth: '100px',
	// 			type: new TextFieldType()
	// 		}, {
	// 			sortable: true,
	// 			title: 'Activités / demande',
	// 			maxWidth: '126px',
	// 			fieldName: 'activityAverage',
	// 			type: new TextFieldType()
	// 		}, {
	// 			sortable: true,
	// 			title: 'Panier moyen',
	// 			maxWidth: '100px',
	// 			fieldName: 'priceAverage',
	// 			type: new TextFieldType()
	// 		}, {
	// 			sortable: true,
	// 			title: '% réduction',
	// 			maxWidth: '100px',
	// 			fieldName: 'discountPercentage',
	// 			type: new TextFieldType()
	// 		}
    //     ],
    //     defaultSort: {
    //         name: "region",
    //         direction: "asc"
    //     },
    //     loadFunc: null
    // };

	private subscriptions: Subscription[] = [];

	constructor(
		public userService: UserService,
		public helper: HelperService,
		private activatedRoute: ActivatedRoute,
		private formBuilder: FormBuilder,
		private router: Router,
		private statsService: StatsService
	) {}

	ngOnInit() {
		this.subscriptions.push(
			combineLatest([
				this.activatedRoute.data,
				this.activatedRoute.queryParams
			])
			.pipe(debounceTime(10))
			.subscribe(([data, params]) => {
				this.stats = data.stats;
				this.initForm(params as StatsRequestParams);
				setTimeout(() => this.initChart());
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	reloadContent() {
		if (this.form.valid) {
			this.chartObject.destroy();
			this.router.navigate([], {
				queryParams: {
					startAt: moment(this.form.get('startAt').value).format(CONSTANTS.DATE_TRANSACTION_FORMAT),
					endAt: moment(this.form.get('endAt').value).format(CONSTANTS.DATE_TRANSACTION_FORMAT),
					regionId: this.form.get('regionId').value,
					zoneId: this.form.get('zoneId').value
				},
				onSameUrlNavigation: 'reload'
			})
		}
	}

	goInvoicePage() {
		const queryParams = {
			...this.form.getRawValue(),
			invoiceStatusNot: BOOKING_STATUS.PAYEE
		}
		this.router.navigate(['dashboard', 'factures'], { queryParams })
	}

	async exportStats() {
		const response = await firstValueFrom(this.statsService.exportStats(this.form.getRawValue()));
		const dateFormat = 'YY_MM_DD';
		const from = moment(this.form.get('startAt').value).format(dateFormat)
		const to = moment(this.form.get('endAt').value).format(dateFormat)
		importedSaveAs(response.body, `JMAE_stats_${from}_au_${to}.xlsx`)
	}

	async exportReport() {
		const response = await firstValueFrom(this.statsService.exportReport(this.form.getRawValue()));
		const dateFormat = 'YY_MM_DD';
		const from = moment(this.form.get('startAt').value).format(dateFormat)
		const to = moment(this.form.get('endAt').value).format(dateFormat)
		importedSaveAs(response.body, `JMAE_rapport_ca_${from}_au_${to}.xlsx`)
	}

	zoneChanged() {
		this.form.get('regionId').patchValue(0);
		this.reloadContent();
	}

	regionChanged() {
		this.form.get('zoneId').patchValue(0);
		this.reloadContent();
	}

	private initForm(params: StatsRequestParams) {
		this.form = this.formBuilder.group({
			zoneId: [params?.zoneId ? parseInt(params.zoneId as unknown as string): 0 || 0, []],
			regionId: [params?.regionId ? parseInt(params.regionId as unknown as string) || 0 : 0, []],
			startAt: [
				params?.startAt ? params?.startAt : moment(new Date().getFullYear().toString() + '01-01', CONSTANTS.DATE_TRANSACTION_FORMAT).toDate(),
				[Validators.required, dateNoFutureValidator()]
			],
			endAt: [
				params?.endAt ? params?.endAt : new Date(),
				[Validators.required, dateNoFutureValidator()]
			]
		})
	}

	private initChart(): void {
		this.chartObject = new Chart(this.chart.nativeElement, {
			type: 'bar',
			data: {
				labels: this.stats.periodCaStats.map(s => s.date),
				datasets: [{
					label: 'CA',
					data: this.stats.periodCaStats.map(s => s.ca),
					backgroundColor: [
						'rgba(255, 99, 132, 0.2)',
						'rgba(255, 159, 64, 0.2)',
						'rgba(255, 205, 86, 0.2)',
						'rgba(75, 192, 192, 0.2)',
						'rgba(54, 162, 235, 0.2)',
						'rgba(153, 102, 255, 0.2)',
						'rgba(201, 203, 207, 0.2)'
					],
					borderColor: [
						'rgb(255, 99, 132)',
						'rgb(255, 159, 64)',
						'rgb(255, 205, 86)',
						'rgb(75, 192, 192)',
						'rgb(54, 162, 235)',
						'rgb(153, 102, 255)',
						'rgb(201, 203, 207)'
					],
					borderWidth: 1
				}]
			},
			options: {
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					},
					tooltip: {
						callbacks: {
							label: (context) => `${context.raw} €`
						}
					}
				},
				scales: {
					y: {
						ticks: {
							callback: (value, index, ticks) => `${value} €`,
							font: {
								size: 9
							}
						}
					},
					x: {
						ticks: {
							font : {
								size: 9
							}
						}
					}
				}
			}
		}) as unknown as Chart
	}
}
