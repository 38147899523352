import { Router } from "@angular/router";
import { AuthService } from "./modules/shared/services/auth.service";

export const appInitializer = (authService: AuthService, router: Router) => {

    return () => {
		authService.testLogin();
		//router.events.subscribe(e => console.log(e))
    }


}
