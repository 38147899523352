import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from 'src/app/modules/shared/services/user.service';
import { ActivityService } from "../../shared/services/activity.service";


@Injectable({ providedIn: 'root' })
export class ActivityResolver {

    constructor(
		public activityService: ActivityService,
		private userService: UserService
	) { }

    resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
		return this.activityService.getActivityById(
			route.params.id,
			this.userService.currentRegion()?.id
		)
    }
}
