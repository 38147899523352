import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { CommercialProposalService } from "../../shared/services/commercial-proposal.service";
import { PaymentCondition } from "../../shared/dtos/payment-conditions.dto";

@Injectable({ providedIn: 'root' })
export class PaymentConditionsResolver {

    constructor(private commercialProposalService: CommercialProposalService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<PaymentCondition[]> {
		return this.commercialProposalService.getPaymentConditions();
    }
}
