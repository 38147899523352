import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import * as moment from 'moment';
import { firstValueFrom, Subscription } from 'rxjs';
import { InvoiceDto } from 'src/app/modules/shared/dtos/invoice/invoice.dto';
import { PageResponse } from 'src/app/modules/shared/dtos/page-response.dto';
import { ROLES } from 'src/app/modules/shared/enums/roles.enum';
import { ClientFilterComponent } from 'src/app/modules/shared/models/client-filter.component';
import { BookingRequestParams, InvoiceRequestParams } from 'src/app/modules/shared/models/request.params';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { CommercialProposalService } from 'src/app/modules/shared/services/commercial-proposal.service';
import { ComparatorService } from 'src/app/modules/shared/services/comparator.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { InvoiceService } from 'src/app/modules/shared/services/invoice.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { dateNoFutureValidator } from 'src/app/modules/shared/validators/date-no-future.validator';
import { CONSTANTS } from 'src/constants';
import { INVOICE_STATUS, INVOICE_STATUS_OBJ } from '../../../shared/enums/invoice-status.enum';
import { InvoiceModal } from '../../modals/invoice-modal/invoice.modal';

@Component({
	selector: 'app-liste-factures',
	templateUrl: './liste-factures.component.html',
	styleUrl: './liste-factures.component.scss'
})

export class ListeFacturesComponent extends ClientFilterComponent implements OnInit, OnDestroy  {

	readonly ROLES = ROLES;
	readonly INVOICE_STATUS = INVOICE_STATUS;
	readonly INVOICE_STATUS_OBJ = INVOICE_STATUS_OBJ;
	readonly PAGINATION_OPTIONS = CONSTANTS.PAGINATION_OPTIONS;
	readonly Object = Object;
	readonly today = new Date();

	pageResponse: PageResponse<InvoiceDto>;

	private subscriptions: Subscription[] = [];

	constructor(
		public activatedRoute: ActivatedRoute,
		public helperService: HelperService,
		public authService: AuthService,
		public userService: UserService,
		public router: Router,
		public appService: AppService,
		public helper: HelperService,
		private formBuilder: FormBuilder,
		private dialog: MatDialog,
		private commercialProposalService: CommercialProposalService,
		private invoiceService: InvoiceService,
		protected override comparatorService: ComparatorService
	) { super(comparatorService)	}

	ngOnInit() {
		this.subscriptions.push(
			this.activatedRoute.data.subscribe(data => {
				this.pageResponse = data.invoicesPage;
				this.customers = data.customersPage.content;
				this.filteredCustomers = this.customers;
			}),
			this.activatedRoute.queryParams.subscribe(params => {
				this.initForm(params as InvoiceRequestParams);
				this.watchSearchCustomers();
				this.initAutoComplete(params as BookingRequestParams);
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	pageChanged(event: PageEvent) {
		this.form.get('offset').patchValue(event.pageIndex)
		this.form.get('limit').patchValue(event.pageSize)
		this.reloadContent();
	}

	zoneChanged() {
		this.form.get('clientId').patchValue(0);
		this.form.get('regionId').patchValue(null)
		this.reloadContent();
	}

	regionChanged() {
		this.form.get('clientId').patchValue(0);
		this.form.get('zoneId').patchValue(null)
		this.reloadContent();
	}

	reloadContent() {
		if (this.form.valid) {
			this.router.navigate([], {
				queryParams: {
					limit: this.form.get('limit').value,
					offset: this.form.get('offset').value,
					zoneId: this.form.get('zoneId').value,
					regionId: this.form.get('regionId').value,
					clientId: this.form.get('clientId').value,
					invoiceStatus: this.form.get('invoiceStatus').value,
					startAt: moment(this.form.get('startAt').value).format(CONSTANTS.DATE_TRANSACTION_FORMAT),
					endAt: moment(this.form.get('endAt').value).format(CONSTANTS.DATE_TRANSACTION_FORMAT)
				},
			})
		}
	}

	async openInvoice(invoice?: InvoiceDto) {
		this.dialog.open(InvoiceModal, {
			data: {
				proposal: await firstValueFrom(this.commercialProposalService.getByBookingId(invoice.bookingId)),
				invoice
			},
		})
		this.subscriptions.push(
			this.dialog.afterAllClosed.subscribe(() => this.reloadContent())
		)
	}

	async pdf(invoice: InvoiceDto) {
		const pdf = await firstValueFrom(this.invoiceService.generateInvoicePdf(invoice.id));
		importedSaveAs(pdf.body, invoice.reference);
	}

	override async initForm(params: InvoiceRequestParams) {
		this.form = this.formBuilder.group({
			offset: [0, [Validators.required]],
			limit: [CONSTANTS.DEFAULT_PAGE_LENGTH, [Validators.required]],
			zoneId: [parseInt(params.zoneId as unknown as string) || 0, []],
			regionId: [parseInt(params?.regionId as unknown as string) || 0, []],
			clientId: [parseInt(params.clientId as unknown as string) || 0, []],
			invoiceStatus: [
				parseInt(params.invoiceStatus as unknown as string) === 0 || !params.invoiceStatus
					? 0
					: params.invoiceStatus,
				[]
			],
			startAt: [
				params?.startAt
					? params?.startAt
					: moment(new Date().getFullYear().toString() + '01-01', CONSTANTS.DATE_TRANSACTION_FORMAT).toDate(),
				[Validators.required, dateNoFutureValidator()]
			],
			endAt: [
				params?.endAt ? params?.endAt : new Date(),
				[Validators.required, dateNoFutureValidator()]
			],
			customerSearch: [""]
		})
	}
}
