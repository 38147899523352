import { BOOKING_STATUS, BOOKING_STATUS_OBJ } from 'src/app/modules/shared/enums/booking-status.enum';
import { combineLatest, filter, Observable, tap } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs as importedSaveAs } from 'file-saver';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Subscription, debounceTime, firstValueFrom } from 'rxjs';
import { weekendsDatesFilter } from 'src/app/modules/shared/adapters/weekends.filter';
import { CreateEventDto } from 'src/app/modules/shared/dtos/booking/create-booking.dto';
import { CommercialProposalDto } from 'src/app/modules/shared/dtos/commercial-proposal/commercial-proposal.dto';
import { ProposalLineDto, ProposalLineUpdateDto } from 'src/app/modules/shared/dtos/commercial-proposal/proposal-line.dto';
import { PaymentCondition } from 'src/app/modules/shared/dtos/payment-conditions.dto';
import { ACTIVITY_TYPES } from 'src/app/modules/shared/enums/activity-type.enum';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { BookingService } from 'src/app/modules/shared/services/booking.service';
import { CommercialProposalService } from 'src/app/modules/shared/services/commercial-proposal.service';
import { EventService } from 'src/app/modules/shared/services/event.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { CONSTANTS } from 'src/constants';
import { BookingDto } from 'src/app/modules/shared/dtos/booking/booking.dto';


@Component({
	selector: 'app-devis-tab.component',
	templateUrl: './devis-tab.component.html',
	styleUrls: ['./devis-tab.component.scss'],
})
export class DevisTabComponent implements OnInit, OnDestroy {

	readonly BOOKING_STATUS_OBJ = BOOKING_STATUS_OBJ;

	proposal: CommercialProposalDto;
	booking: BookingDto;
	paymentConditions: PaymentCondition[] = [];

	ACTIVITY_TYPES = ACTIVITY_TYPES;
	weekendsDatesFilter = weekendsDatesFilter;
	form: FormGroup;
	lockAdditionnalFees: boolean;
	recap: ProposalLineDto[][] = [];

	private subscriptions: Subscription[] = [];

	constructor(
		public appService: AppService,
		public authService: AuthService,
		public helper: HelperService,
		public bookingService: BookingService,
		private activatedRoute: ActivatedRoute,
		private proposalService: CommercialProposalService,
		private formBuilder: FormBuilder,
		private eventService: EventService,
		private router: Router
	) { }


	async ngOnInit(): Promise<void> {
		this.subscriptions.push(
			combineLatest([
				this.activatedRoute.parent.data,
				this.activatedRoute.data
			]).subscribe(([parentData, data]) => {
				this.proposal = data.proposal;
				this.paymentConditions = data.paymentConditions;
				this.setLockAdditionnalFees(parentData.booking.status)
				this.fixDates();
				this.initForm(parentData.booking.status);
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	get lines() {
		return this.form ? this.form.controls["lines"] as FormArray : new FormArray([]);
	}

	getTotalHT(): number {
		let total = 0;
		this.form.get('lines').value.forEach((l, i) => total += this.getLineTotalPrice(i));
		return total + this.getApplicationFees();
	}

	getTotalTTC(): number {
		return this.getTotalHT() + this.getVat();
	}

	getApplicationFees(): number {
		return this.form.get('applyApplicationFees').value
			? this.form.get('applicationFees').value
			: 0;
	}

	getApplicationFeesTTC(): number {
		return this.form.get('applyVAT').value
			? (this.getApplicationFees() * 1.2)
			: this.getApplicationFees()
	}

	getVat(): number {
		return this.form.get('applyVAT').value
			? this.getTotalHT()* 0.2
			: 0;
	}

	getUnitPrice(lineIndex: number): number {
		const lineForm = (this.form.get("lines") as FormArray).at(lineIndex);
		const line = this.proposal.lines.find(l => l.id === lineForm.get('lineId').value);
		const onDemandPrice = lineForm.get('onDemandPrice').value;
		const result = line.event.activity.activityType === ACTIVITY_TYPES.AUTRE
			? onDemandPrice
			: line.price

		return result > 0 ? result: 0;
	}

	getLineTotalPrice(lineIndex: number): number {
		const lineForm = (this.form.get("lines") as FormArray).at(lineIndex);
		const unitPrice = this.getUnitPrice(lineIndex);
		const priceVariation =  (1 - lineForm.get('priceVariation').value / 100)
		const result = unitPrice * priceVariation
		return result > 0 ? result: 0;
	}

	async pdf() {
		const content = await firstValueFrom(this.proposalService.getPdfProposalById(this.proposal.id));
		importedSaveAs(content.body, this.proposal.reference + '.pdf')
	}

	getGrandTotal(): number {
		return this.getTotalTTC() + this.proposal.applicationFees;
	}

	private setRecap(): void {
		const groups = _.groupBy(this.proposal.lines, (line) => line.event.offer.id);
		const sort = (a, b) => a[0].event.activity.title > b[0].event.activity.title ? 1 : -1;
		this.recap = _.toArray(groups).sort(sort);
	}

	getRecapLineSum(lines: ProposalLineDto[]): number {
		let result = 0;
		lines.forEach((line, i) => {
			const formLineIndex = this.lines?.getRawValue().findIndex(l => l.lineId === line.id);
			if (formLineIndex > -1) {
				result += this.getLineTotalPrice(formLineIndex);
			}

		})
		return this.form?.get('applyVAT').value ? result * 1.2 : result;
	}

	getRecapLineDiscount(lines: ProposalLineDto[]): number {
		let total = 0;
		lines.forEach((line) => {
			const formLineIndex = this.proposal.lines.findIndex(l => l.id === line.id);
			total += parseInt(this.lines.at(formLineIndex).getRawValue().priceVariation, 10);
		})
		return total / lines.length;
	}

	private setLockAdditionnalFees(bookingStatus): void {
		this.lockAdditionnalFees = this.proposal.lines.filter(l => l.invoiced).length > 0
			|| BOOKING_STATUS_OBJ[bookingStatus].order >= BOOKING_STATUS_OBJ[BOOKING_STATUS.DEVIS_SIGNE].order
	}

	async copyLine(lineIndex: number) {
		if (confirm(CONSTANTS.YOU_SURE)) {
			const lineForm = (this.form.get("lines") as FormArray).at(lineIndex);
			const dto: CreateEventDto = {
				activityId: lineForm.get('activityId').value,
				date: lineForm.get('date').value,
				offerId: lineForm.get('offerId').value,
				bookingId: this.proposal.bookingId
			}
			await firstValueFrom(this.eventService.addEventToBooking(dto));
			this.refreshScreen();
		}
	}

	async deleteLine(lineIndex) {
		if (confirm(CONSTANTS.YOU_SURE + '\nLes éventuelles réservations liées à cette activité seront supprimées.\nCette action est irréversible.')) {
			const lineForm = (this.form.get("lines") as FormArray).at(lineIndex);
			await firstValueFrom(this.eventService.removeEvent(lineForm.get('eventId').value));
			this.refreshScreen();
		}
	}

	private fixDates(): void {
		this.proposal.lines = this.proposal.lines.map(line => {
			return {
				...line,
				date: moment(line.event.date).toDate()
			}
		})
	}

	private initForm(bookingStatus: BOOKING_STATUS): void {
		this.form = this.formBuilder.group({
			lines: this.formBuilder.array(
				this.proposal.lines.map(line => {
					return this.formBuilder.group({
						lineId: [line.id, [Validators.required]],
						activityName: [line.activityTitle],
						activityType: [line.activityType],
						activityId: [line.event.activity.id],
						eventId: [line.event.id],
						date: [line.event.date, [Validators.required]],
						offerId: [{
							value: line.event.offer.id,
							disabled: line.event.activity.activityType === ACTIVITY_TYPES.INDIVIDUELLE
								|| line.invoiced
								|| BOOKING_STATUS_OBJ[bookingStatus].order >= BOOKING_STATUS_OBJ[BOOKING_STATUS.DEVIS_SIGNE].order
						}, [Validators.required]],
						onDemandPrice: [{
							value: line.price,
							disabled: line.invoiced
									|| BOOKING_STATUS_OBJ[bookingStatus].order >= BOOKING_STATUS_OBJ[BOOKING_STATUS.DEVIS_SIGNE].order
						}, [Validators.required]],
						priceVariation: [{
							value: line.priceVariation,
							disabled: line.event.activity.activityType === ACTIVITY_TYPES.INDIVIDUELLE
									|| line.invoiced
									|| BOOKING_STATUS_OBJ[bookingStatus].order >= BOOKING_STATUS_OBJ[BOOKING_STATUS.DEVIS_SIGNE].order
						}, [Validators.required, Validators.min(0), Validators.max(20)]]
					})
				})
			),
			applyApplicationFees: [{
				value: this.proposal.applyApplicationFees,
				disabled: this.lockAdditionnalFees
			}],
			applicationFees: [{
				value: this.proposal.applicationFees,
				disabled: this.lockAdditionnalFees
			}],
			applyVAT: [{
				value: this.proposal.applyVAT,
				disabled: this.lockAdditionnalFees
			}],
			paymentConditionsId: [{
				value: this.proposal.paymentConditions.id,
				disabled: this.lockAdditionnalFees
			}]
		})
		this.watchFormValuesChange()
		this.setRecap();
	}

	private watchFormValuesChange(): void {
		this.watchFormLineValueChange();
		this.watchApplyApplicationFeesChanges();
		this.watchApplicationFeesChanges();
		this.watchApplyVATChanges();
	}

	private watchFormLineValueChange(): void {
		for (var i = 0; i < (this.form.get('lines') as FormArray).controls.length; i++) {
			this.subscriptions.push(
				this.form.get('lines')
					.get(i.toString())
					.valueChanges
					.pipe(
						debounceTime(300),
						filter(changed => changed.priceVariation <= 20 && changed.priceVariation >= 0)
					)
					.subscribe(changed => {
						this.updateLineRemote({
							id: changed.lineId,
							date: changed.date,
							offerId: changed.offerId,
							onDemandPrice: changed.onDemandPrice,
							priceVariation: changed.priceVariation
						})
						this.setRecap();
					})
			)
		}
	}

	private async watchApplyApplicationFeesChanges() {
		this.subscriptions.push(
			this.form.get('applyApplicationFees').valueChanges.pipe(debounceTime(300)).subscribe(async changed => {
				await firstValueFrom(this.proposalService.togglePaymentFee(
					this.proposal.id,
					this.form.get('applyApplicationFees').value)
				);
				this.setRecap();
			})
		)
	}

	private async watchApplicationFeesChanges() {
		this.subscriptions.push(
			this.form.get('applicationFees').valueChanges.pipe(debounceTime(300)).subscribe(async changed => {
				await firstValueFrom(this.proposalService.updateApplicationFees(
					this.proposal.id,
					this.form.get('applicationFees').value)
				);
				this.setRecap();
			})
		)
	}

	private async watchApplyVATChanges() {
		this.subscriptions.push(
			this.form.get('applyVAT').valueChanges.pipe(debounceTime(300)).subscribe(async changed => {
				await firstValueFrom(this.proposalService.toggleVAT(
					this.proposal.id,
					this.form.get('applyVAT').value
				));
				this.setRecap();
			})
		)
	}

	// private async watchPaymentConditionsIdChange() {
	// 	this.subscriptions.push(
	// 		this.form.get('paymentConditionsId').valueChanges.pipe(debounceTime(300)).subscribe(async changed => {
	// 			await firstValueFrom(this.proposalService.updatePaymentConditions(
	// 				this.proposal.id,
	// 				this.paymentConditions.find(pc => pc.id === this.form.get('paymentConditionsId').value)
	// 			));
	// 		})
	// 	)
	// }

	private async updateLineRemote(dto: ProposalLineUpdateDto) {
		const updatedLine = await firstValueFrom(this.proposalService.updateProposalLine(this.proposal.id, dto));
		const index = this.proposal.lines.findIndex(l => l.id === updatedLine.id);
		this.proposal.lines[index] = updatedLine;
	}

	private refreshScreen() {
		this.router.navigate([]);
	}
}
