import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { StatsDto } from "../../shared/dtos/stats/stats.dto";
import { StatsRequestParams } from "../../shared/models/request.params";
import { StatsService } from "../../shared/services/stats.service";


@Injectable({ providedIn: 'root' })
export class StatsResolver {

    constructor(private statsService: StatsService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<StatsDto> {
		return this.statsService.getStats(route.queryParams as StatsRequestParams)
    }

}
