<section class="container-fluid">
	<div class="header">
		<h1>Toutes les factures</h1>

		<form class="btn-group" [formGroup]="form">
			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Zone géographique</mat-label>
					<mat-select formControlName="zoneId" (selectionChange)="zoneChanged()">
						<mat-option [value]="0">Toutes les zones</mat-option>
						<mat-option *ngFor="let zone of userService.currentUser().zones" [value]="zone.id">
							{{zone.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>État de la facture</mat-label>
					<mat-select formControlName="invoiceStatus" (selectionChange)="reloadContent()">
						<mat-option [value]="0">Tous les états</mat-option>
						<mat-option *ngFor="let key of Object.keys(INVOICE_STATUS_OBJ)" [value]="key">
							{{INVOICE_STATUS_OBJ[key].label}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>A partir de</mat-label>
					<input matInput required
						formControlName="startAt"
						[matDatepicker]="startPicker"
						placeholder="Date"
						(dateChange)="reloadContent()"
						[max]="form.get('endAt').value"
					>
					<mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
					<mat-datepicker #startPicker></mat-datepicker>
				</mat-form-field>
			</div>

			<div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Jusqu'à</mat-label>
					<input matInput required
						formControlName="endAt"
						[matDatepicker]="endPicker"
						placeholder="Date"
						(dateChange)="reloadContent()"
						[max]="today"
						[min]="form.get('startAt').value"
					>
					<mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
					<mat-datepicker #endPicker></mat-datepicker>
				</mat-form-field>
			</div>

			<!-- <div class="custom-field">
				<mat-form-field subscriptSizing="dynamic">
					<mat-label>Région</mat-label>
					<mat-select formControlName="regionId" (selectionChange)="regionChanged()">
						<mat-option [value]="0">Toutes les régions</mat-option>
						<mat-option *ngFor="let region of userService.currentUser().regionalCommittees" [value]="region.id">
							{{region.title}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div> -->

			<div class="custom-field" style="max-width: 500px; width: 500px;">
				<mat-form-field>
					<mat-label>Client</mat-label>
					<input matInput type="text" [matAutocomplete]="auto" formControlName="customerSearch">
					<mat-autocomplete
						#auto="matAutocomplete"
						[displayWith]="clientDisplayName"
						(optionSelected)="customerChanged($event)"
					>
						<mat-option *ngFor="let customer of filteredCustomers" [value]="customer">
							<div class="is-flex is-align-items-center">
								<mat-label style="flex:1">{{clientDisplayName(customer)}}</mat-label>
							</div>
						</mat-option>
						<mat-option [disabled]="true" *ngIf="filteredCustomers?.length === 0">
							Aucun résultat
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>

			</div>
		</form>
	</div>

	<p id="no-response" *ngIf="pageResponse?.totalElements === 0">Aucune facture pour l'instant.</p>

	<div class="box" *ngIf="pageResponse?.totalElements > 0">

		<mat-paginator
			[length]="pageResponse?.totalElements"
			[pageSize]="pageResponse.size"
			[pageSizeOptions]="PAGINATION_OPTIONS"
			[pageIndex]="pageResponse?.number"
			(page)="pageChanged($event)">
		</mat-paginator>

		<div id="table-container">
			<table class="table is-striped is-fullwidth" *ngIf="pageResponse.content.length > 0">
				<thead>
					<tr>
						<th>Référence</th>
						<th>Client</th>
						<th>Date d'édition</th>
						<th>Date d'envoi</th>
						<th>Date limite de paiement</th>
						<th>Date de paiement</th>
						<th>Référence client</th>
						<th style="width: 100px">Montant HC</th>
						<th style="width: 100px">Montant TTC</th>
						<th>État</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let invoice of pageResponse.content">
						<td><button class="button is-primary is-inverted" (click)="openInvoice(invoice)">#{{invoice.reference}}</button></td>
						<td>{{invoice.client.companyName}}</td>
						<td>{{helper.dateFr(invoice.creationDate) || "indéterminé"}}</td>
						<td>{{!!invoice.sendDate ? helper.dateFr(invoice.sendDate) : "-"}}</td>
						<td>{{!!invoice.paymentDateLimit ? helper.dateFr(invoice.paymentDateLimit) : "-"}}</td>
						<td>{{!!invoice.paymentDate ? helper.dateFr(invoice.paymentDate) : "-"}}</td>
						<td>{{invoice.clientReference || "-"}}</td>
						<td>{{invoice.amountHC}}</td>
						<td>{{invoice.amount}}</td>
						<td>
							<div class="status" [ngClass]="invoice.status">
								{{INVOICE_STATUS_OBJ[invoice.status].label}}
							</div>
						</td>
						<td>
							<button class="button is-danger is-inverted" (click)="pdf(invoice)">
								<mat-icon>picture_as_pdf</mat-icon>
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<mat-paginator
				[length]="pageResponse?.totalElements"
				[pageSize]="pageResponse.size"
				[pageSizeOptions]="PAGINATION_OPTIONS"
				[pageIndex]="pageResponse?.number"
				(page)="pageChanged($event)">
		</mat-paginator>
	</div>

</section>
