import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BookingMinimalDto } from 'src/app/modules/shared/dtos/booking/booking-minimal.dto';
import { PageResponse } from 'src/app/modules/shared/dtos/page-response.dto';
import { ROLES } from 'src/app/modules/shared/enums/roles.enum';
import { ClientFilterComponent } from 'src/app/modules/shared/models/client-filter.component';
import { BookingRequestParams } from 'src/app/modules/shared/models/request.params';
import { AppService } from 'src/app/modules/shared/services/app.service';
import { AuthService } from 'src/app/modules/shared/services/auth.service';
import { ComparatorService } from 'src/app/modules/shared/services/comparator.service';
import { HelperService } from 'src/app/modules/shared/services/helper.service';
import { UserService } from 'src/app/modules/shared/services/user.service';
import { BOOKING_STATUS, BOOKING_STATUS_OBJ } from '../../../shared/enums/booking-status.enum';
import { CONSTANTS } from './../../../../../constants';

@Component({
	selector: 'app-liste-demandes',
	templateUrl: './liste-demandes.component.html',
	styleUrl: './liste-demandes.component.scss'
})

export class ListeDemandesComponent extends ClientFilterComponent implements OnInit, OnDestroy {


	readonly ROLES = ROLES;
	readonly BOOKING_STATUS = BOOKING_STATUS;
	readonly BOOKING_STATUS_OBJ = BOOKING_STATUS_OBJ;
	readonly PAGINATION_OPTIONS = CONSTANTS.PAGINATION_OPTIONS;
	readonly Object = Object;

	pageResponse: PageResponse<BookingMinimalDto>;

	private subscriptions: Subscription[] = [];

	constructor(
		public activatedRoute: ActivatedRoute,
		public helperService: HelperService,
		public authService: AuthService,
		public userService: UserService,
		public router: Router,
		public appService: AppService,
		public helper: HelperService,
		private formBuilder: FormBuilder,
		protected override comparatorService: ComparatorService
	) {	super(comparatorService)}

	ngOnInit() {
		this.subscriptions.push(
			this.activatedRoute.data.subscribe(data => {
				this.pageResponse = data.bookingsPage;
				this.customers = data.customersPage.content;
				this.filteredCustomers = this.customers;
			}),
			this.activatedRoute.queryParams.subscribe(params => {
				this.initForm(params as BookingRequestParams);
				this.watchSearchCustomers();
				this.initAutoComplete(params as BookingRequestParams);
			})
		)
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(s => s.unsubscribe());
	}

	pageChanged(event: PageEvent) {
		this.form.get('offset').patchValue(event.pageIndex)
		this.form.get('limit').patchValue(event.pageSize)
		this.reloadContent();
	}

	regionChanged() {
		this.form.get('clientId').patchValue(0);
		this.form.get('zoneId').patchValue(0);
		this.reloadContent();
	}

	zoneChanged() {
		this.form.get('clientId').patchValue(0);
		this.form.get('regionId').patchValue(0);
		this.reloadContent();
	}

	reloadContent() {
		if (this.form.valid) {
			this.router.navigate([], {
				queryParams: this.form.getRawValue(),
				onSameUrlNavigation: 'reload'
			})
		}
	}

	override async initForm(params: BookingRequestParams) {
		this.form = this.formBuilder.group({
			offset: [0, [Validators.required]],
			limit: [CONSTANTS.DEFAULT_PAGE_LENGTH, [Validators.required]],
			zoneId: [parseInt(params.zoneId as unknown as string) || 0, []],
			regionId: [parseInt(params.regionId as unknown as string) || 0, []],
			clientId: [parseInt(params.clientId as unknown as string) || 0, []],
			bookingStatus: [
				parseInt(params.bookingStatus as unknown as string) === 0 || !params.bookingStatus
					? 0
					: params.bookingStatus,
				[]
			],
			customerSearch: [""]
		})
	}

}
